<template>
  <v-sheet v-if="$store.state.isUserLoggedIn" rounded="lg">
    <div v-if="loading">
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </div>
    <v-card v-else>
      <v-card-text v-if="instance && instance.syllabusTemplate">
        <div class="text-h5">{{instance.syllabusTemplate.name}}</div>
        <div class="text-caption">{{ instance.syllabusTemplate.description }}</div>
        <div class="text-subtitle-1 mt-2" v-if="instance.syllabusTemplate.useBelts && nextLevel">
          {{$t("message.progress_next_level")}}:
          <BeltDisplay size="15" :belt="nextLevel"></BeltDisplay>
        </div>
        <div class="text-subtitle-1" v-else-if="nextLevel">{{ $t("message.progress_next_level") }}: {{ nextLevel }}
        </div>
      </v-card-text>
      <v-list v-if="instance && instance.data && instance.data.length > 0">
        <template v-for="(d, index) in instance.data">
          <v-subheader :key="'syllabus_instance_header_'+index+'_'+d._id">
            <v-progress-linear v-if="color" :color="color" height="25" striped :value="d.overallPercent">
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-subheader>
          <template v-for="(rule, ruleIndex) in d.instanceRules">
            <v-list-item :key="'syllabus_instance_data_rule_'+ruleIndex+'_'+d._id" class="text-left">
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="rule.ruleType == 'checkins'">
                    <v-icon class="ml-1">mdi-map-marker-outline</v-icon>
                    {{$t('message.regular_checkins')}}
                  </span>
                  <span v-else-if="rule.ruleType == 'checkins_contains'">
                    <v-icon class="ml-1">mdi-map-marker-outline</v-icon>{{rule.ruleId}}
                  </span>
                  <span v-else-if="rule.ruleType == 'checkins_matches'">
                    <v-icon class="ml-1">mdi-map-marker-outline</v-icon>{{rule.ruleId}}
                  </span>
                  <span v-else-if="rule.ruleType == 'number'">
                    <v-icon class="ml-1">mdi-chevron-double-up</v-icon>{{rule.ruleId}}
                  </span>
                  <span v-else-if="rule.ruleType == 'boolean'">
                    <v-icon class="ml-1">mdi-toggle-switch-off-outline</v-icon>{{rule.ruleId}}
                  </span>
                  <span v-else-if="rule.ruleType == 'video'">
                    <v-icon class="ml-1">mdi-video-outline</v-icon>{{rule.ruleId}}
                  </span>
                  <span v-else-if="rule.ruleType == 'text'">
                    <v-icon class="ml-1">mdi-text-box-outline</v-icon>{{rule.ruleId}}
                  </span>
                  <span v-else-if="rule.ruleType == 'flowchart'">
                    <v-icon class="ml-1">mdi-sitemap-outline</v-icon>{{ rule.ruleId }}
                  </span>
                  <span class="gray--text ml-1"
                    v-if="rule.ruleType && rule.ruleType.indexOf('checkins') >= 0">{{rule.numberValue ? rule.numberValue
                    :
                    0}}/{{rule.numberNeeded}}</span>
                  <span style="color:gray;margin-left:5px" v-else-if="rule.numberValue != null">{{rule.numberValue ?
                    rule.numberValue : 0}}/{{rule.numberNeeded}}</span>
                </v-list-item-title>
                <v-list-item-subtitle v-if="rule.details">
                  <div class="ml-2" v-if="rule.details.length < 60">
                    {{rule.details}}
                  </div>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate ml-2" v-bind="attrs" v-on="on">
                        {{rule.details}}
                      </div>
                    </template>
                    <span>{{rule.details}}</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <span v-if="rule.ruleType == 'video' && rule.booleanValue">
                  <v-icon color="green" v-if="rule.booleanValue">mdi-check-circle-outline</v-icon>
                </span>
                <span v-else-if="rule.ruleType == 'text' && rule.booleanValue">
                  <v-icon color="green" v-if="rule.booleanValue">mdi-check-circle-outline</v-icon>
                </span>
                <span v-else-if="rule.numberValue != null && rule.numberValue >= rule.numberNeeed">
                  <v-icon color="green" v-if="rule.booleanValue">mdi-check-circle-outline</v-icon>
                </span>
                <span v-else>
                  <v-icon color="green" v-if="rule.booleanValue">mdi-check-circle-outline</v-icon>
                </span>
              </v-list-item-action>
            </v-list-item>
            <div v-if="rule.ruleType == 'video'" :key="'syllabus_instance_data_rule_video_'+ruleIndex+'_'+d._id"
              class="pa-2">
              <VideoDisplay :video="rule.video" :on-completion="onCompletion"></VideoDisplay>
            </div>
            <div v-if="rule.ruleType == 'flowchart'" class="ma-2"
              :key="'syllabus_instance_data_rule_flowchart_' + ruleIndex + '_' + d._id">
              <div v-if="rule.flowchart" class="text-center text-subtitle-1">
                {{ rule.flowchart.name }}
              </div>
              <div v-if="rule.flowchart">
                <v-btn block color="primary" outlined elevation="0" @click="viewFlowchart(rule)">
                  <v-icon class="mr-1">mdi-sitemap-outline</v-icon>
                  {{ $t('message.view_flowchart') }}
                </v-btn>
              </div>
            </div>
            <div v-if="rule.ruleType == 'text'" :key="'syllabus_instance_data_rule_text_'+ruleIndex+'_'+d._id"
              class="pa-2 pb-0">
              <v-card-text class="page-description mt-0" v-html="rule.longText"></v-card-text>
              <v-card-actions>
                <v-btn v-if="!rule.booleanValue" block color="primary" small elevation="0"
                  @click="completeRule(rule.instanceDataId, rule.ruleId, true)">
                  {{$t("message.mark_complete")}}
                </v-btn>
              </v-card-actions>
            </div>
            <v-divider :key="'syllabus_instance_data_rule_divider_'+ruleIndex+'_'+d._id"></v-divider>
          </template>
        </template>
      </v-list>
    </v-card>
    <v-dialog v-model="flowchartDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card outlined class="pa-0">
        <v-app-bar flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="flowchartDialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-app-bar>
        <v-skeleton-loader v-if="loadingFlowchart" type="card, article"></v-skeleton-loader>
        <flowy v-if="flowchartDialog" class="flowchart-container" :nodes="previewNodes">
        </flowy>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>
<script>
import SyllabusService from "@/services/SyllabusService";
import JournalService from "@/services/JournalService";
import VideoDisplay from "@/components/displays/VideoDisplay.vue";
import NotificationService from "@/services/NotificationService";
import BeltDisplay from "@/components/displays/BeltDisplay.vue";
import FlowchartNodeView from "@/components/displays/FlowchartNodeView.vue";

export default {
  data() {
    return {
      instanceId: this.$route.params.instanceId,
      notificationId: this.$route.params.notificationId,
      managedAcademy: this.$store.state.userManagedAcademy,
      instance: null,
      loading: true,
      colors: null,
      flowchartDialog: null,
      loadingFlowchart: false,
      previewNodes: [],
      color: null,
      nextLevel: null,
      onCompletion: function (video) {
        video.completeMe(video.instanceDataId, video.ruleId, true)
      }
    };
  },
  components: {
    VideoDisplay,
    BeltDisplay,
  },
  created() {
    if (this.notificationId) this.readNotification();
    this.loadDetails();
  },
  methods: {
    viewFlowchart(rule) {
      this.loadingFlowchart = true;
      this.flowchartDialog = true;
      JournalService.getFlowchart(this.managedAcademy, rule.flowchart._id)
        .then((data) => {
          if (data && data.flowchart)
            this.loadChartNodesPreviewMode(data.flowchart.nodeIds);
          this.loadingFlowchart = false;
          this.completeRule(rule.instanceDataId, rule.ruleId);
        }).catch((err) => {
          console.log(err)
          this.showAlert("error", this.$t("message.error_generic"));
        });
    },
    loadChartNodesPreviewMode(nodes) {
      this.previewNodes = [];
      let previewNodes = [];
      if (nodes) {
        for (const n of nodes) {
          previewNodes.push(
            {
              id: n.id,
              parentId: n.parentId,
              nodeComponent: FlowchartNodeView,
              type: n.nodeType,
              title: n.title,
              technique: this.getTechniqueTitle(n.nodeType),
              description: n.description,
              adminVideo: n.adminVideo,
              videoUrl: n.videoUrl,
              imageUrl: n.imageUrl,
              flowchartId: this.flowchartId,
            }
          );
        }
        this.previewNodes = previewNodes;
        this.loading = false;
      }
    },
    readNotification() {
      NotificationService.read(this.notificationId);
    },
    completeRule(instanceDataId, ruleId, showAlert) {
      SyllabusService.completeRule(instanceDataId, ruleId)
        .then(() => {
          if (showAlert) {
            this.showAlert(
              "success",
              this.$t("message.updated")
            );
          }
          if (this.instance.data && this.instance.data) {
            for (let d of this.instance.data) {
              if (d._id == instanceDataId) {
                if (d.instanceRules) {
                  for (let rule of d.instanceRules) {
                    if (rule.ruleId == ruleId) {
                      rule.booleanValue = true;
                    }
                }
                }
              }
            }
          }
        });
    },
    loadDetails () {
      SyllabusService.details(this.instanceId)
        .then((response) => {
          this.instance = response.instance;
          if(response.instance.syllabusTemplate.useBelts){
            this.color = this.getBeltColorForProgress(response.instance.currentLevel);
          }else if(response.colors && response.colors[response.instance.currentLevel]){
            this.color = response.colors[response.instance.currentLevel];
          }else{
            this.color = this.getColor('light');
          }
          this.colors = response.colors;
          this.nextLevel = response.next;
          this.loading = false;
          const templateRulesDefinitions = response.rules;
          for(const levelData of response.instance.data){
            if(response.instance.currentLevel == levelData.level && levelData.overallPercent == 100)
              this.canLevelUp = true
              //Add rule type for each level rule
            if(templateRulesDefinitions && templateRulesDefinitions[levelData.level]){
              for(let instanceRule of levelData.instanceRules){
                if(templateRulesDefinitions[levelData.level][instanceRule.ruleId]){
                  instanceRule.ruleType = templateRulesDefinitions[levelData.level][instanceRule.ruleId].ruleType;
                  instanceRule.details = templateRulesDefinitions[levelData.level][instanceRule.ruleId].details;
                  instanceRule.video = templateRulesDefinitions[levelData.level][instanceRule.ruleId].video;
                  instanceRule.flowchart = templateRulesDefinitions[levelData.level][instanceRule.ruleId].flowchart;
                  if (instanceRule.video) {
                    instanceRule.video.instanceDataId = levelData._id;
                    instanceRule.video.ruleId = instanceRule.ruleId;
                    instanceRule.video.completeMe = this.completeRule;
                  }
                  instanceRule.instanceDataId = levelData._id;
                  instanceRule.longText = templateRulesDefinitions[levelData.level][instanceRule.ruleId].longText;
                }
              }
            }
          }
        });
    },
  },
  computed: {},
  watch: {}
};
</script>

<style scoped>
.agreement_iframe{
  width: 100%;
  max-height: 500px;
}
</style>