<template>
  <div>
    <v-autocomplete
      @input="event => { this.$emit('input', event) }"
      :label="label ? label : $t('message.search_academies')"
      :loading="loading"
      outlined
      dense
      :items="items"
      item-text="name"
      item-value="_id"
      :search-input.sync="search"
      v-model="selectedValue"
      hide-details
    >
    </v-autocomplete>
    <p v-if="!hideMessage" 
      class="text-small text-left">
      {{$t('message.dont_see_academy')}} 
      <a @click="navigateTo({ name: 'addacademy', query: { from: from ? from : '' } })">
        {{$t('message.here')}}
      </a>
    </p>
  </div>
</template>
<script>
import AcademyService from "@/services/AcademyService";

export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      selectedValue: null,
      academies: []
    };
  },
  components: {
  },
  props: {
    value: null,
    from: null,
    hideMessage: null,
    label: null
  },
  created() {
    AcademyService.academiesSimplified()
      .then(data => {
        if (data) {
          this.items = data.academies;
        }
      });
  },
  mounted() {
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newValue) {
        this.selectedValue = newValue;
      }
    }
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      if (this.academies && this.academies.length == 0){
        let data = await AcademyService.academiesSimplified();
        if(data)
          this.academies = data.academies;
      }
      this.items = this.academies.filter(e => {
        return (
          (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        );
      });
      this.loading = false;
    }
  }
};
</script>

<style scoped>
</style>
