<template>
  <v-combobox
    v-model="selectedValue"
    :items="masters"
    item-text="text"
    item-value="_id"
    :search-input.sync="search"
    :hide-no-data="!search"
    @input="event => { $emit('input', event) }"
    :label="$t('message.select_professor')" 
    outlined
    small-chips
    dense
    :loading="loading"
  >
    <template slot="no-data">
      <v-list-item>
        <span class="subheading">{{ $t('message.click_enter_add_new') }}: </span>
        <v-chip
          label
          small
        >
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import LineageService from "@/services/LineageService";

export default {
  data() {
    return {
      loading: false,
      selectedValue: null,
      masters: [],
      search: null
    };
  },
  created() {
    this.loading = true;
    LineageService.masters({})
      .then(data => {
        if (data) {
          this.masters = data.masters;
          this.loading = false;
        }
      });
  },
  mounted(){
    this.selectedValue = this.value
  },
  props: {
    value: null,
  },
  watch: {
    search(val) {
      val && this.querySelections(val);
    },
    value: {
      handler: function(newValue) {
        this.selectedValue = newValue;
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      let data = await LineageService.masters({filter: v});
      if(data){
        const tmp = data.masters;
        this.masters = tmp.filter(e => {
          return ((e.text || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1);
        });
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
</style>
