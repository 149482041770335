<template>
  <v-container fluid dark>
    <v-layout row wrap>
      <v-flex class="container-bg" xs12 sm6>
        <div class="content-register">
          <img style="width:120px; margin-bottom: 20px"
            src="https://res.cloudinary.com/freddyci/image/upload/v1678128741/bjjlink-logo-8866ff_ppbegv.svg" />
          <h2 class="header my-2">{{ $t('message.register_an_account') }}</h2>
          <p class="eco-text">
            {{ $t('message.register_subtext') }}
          </p>
          <div class="form-container">
            <v-form v-model="valid" ref="form" lazy-validation class="ma-12">
              <v-stepper dark v-model="e1" elevation="0" class="reg-form-bg">
                <v-stepper-header elevation="0">
                  <v-stepper-step :complete="e1 > 1" step="1">
                    {{ $t('message.email') }}
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 2" step="2">
                    {{ $t('message.name') }}
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3">
                    {{ $t('message.details') }}
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">

                    <v-text-field label="Email" v-model="remail" :rules="emailRules" required outlined
                      dense></v-text-field>
                    <v-text-field v-show="fbId ? false : true" type="password" :label="$t('message.password')"
                      v-model="rpassword" autocomplete="new-password" required outlined dense></v-text-field>
                    <v-text-field v-show="fbId ? false : true" type="password" :label="$t('message.retype_password')"
                      v-model="rpassword2" autocomplete="new-password" required outlined dense></v-text-field>
                    <v-card-actions class="pa-0">
                      <v-spacer></v-spacer>
                      <v-btn small fab class="elevation-0 chev-color" @click="e1 = 2">
                        <ChevronRightIcon class="hero-button"></ChevronRightIcon>
                      </v-btn>
                    </v-card-actions>
                  </v-stepper-content>

                  <v-stepper-content step="2">

                    <v-text-field outlined dense :label="$t('message.first_name')" v-model="rfName" autocomplete="false"
                      required></v-text-field>
                    <v-text-field outlined dense :label="$t('message.last_name')" v-model="rlName"
                      required></v-text-field>
                    <v-card-actions class="pa-0">
                      <v-btn class="elevation-0 chev-color" fab small @click="e1 = 1">
                        <ChevronLeftIcon class="hero-button"></ChevronLeftIcon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn fab small @click="e1 = 3" class="elevation-0 chev-color">
                        <ChevronRightIcon class="hero-button"></ChevronRightIcon>
                      </v-btn>
                    </v-card-actions>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <gender-select :icon="true" v-model="rgender" />
                    <belts-select :label="$t('message.select_belt')" :class="fbId && !rbelt ? 'fb-extra' : ''"
                      v-model="rbelt" show-icon="true" no-bjj="true" />
                    <countries-select :label="$t('message.select_country')"
                      :class="fbId && !rnationality ? 'fb-extra' : ''" v-model="rnationality" :show-icon="true" />
                    <v-switch :label="$t('message.at_least_13')" v-model="ageMin"></v-switch>
                    <div v-html="error" class="registration_error text-center" />
                    <v-card-actions style="margin-bottom: 10px" class="pa-0">
                      <v-btn class="elevation-0 chev-color" fab small @click="e1 = 2">
                        <ChevronLeftIcon class="hero-button"></ChevronLeftIcon>
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions class="mb-0">
                      <v-spacer></v-spacer>
                      <v-btn v-if="!loginData" @click="register" class="elevation-0 regbtn pa-6" :disabled="!valid">
                        <span v-show="!registring">{{ $t("message.register") }}</span>
                        <v-progress-circular v-show="registring" indeterminate color="white"></v-progress-circular>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-card-text class="text-center text-caption eco-text my-6">
                      <div v-html="$t('message.accepting_terms', [termsUrl])"></div>
                    </v-card-text>
                  </v-stepper-content>

                    <div v-if="academyName">
                      <span class="text-subtitle-1">{{ $t("message.joining_academy") }} <b class="primary--text">{{ academyName }}</b></span>
                    </div>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </div>
          <div>
            <p class="newtext">{{ $t("message.have_account") }} <a @click="goToLogin()" style="color:#8866ff">{{
                $t("message.login_here") }}</a></p>
          </div>
        </div>
      </v-flex>
      <v-flex class="text-center" xs12 sm6 style="height: 100vh; position: relative;">
        <v-img src="https://bjjlink-pub.s3.us-west-2.amazonaws.com/login-bg.png" aspect-ratio="1.5"
          height="100%"></v-img>
        <v-card class="overlay-card" elevation="5">
          <v-card-text>
            <h2 class="text-center eco-header">{{ $t('message.ecosystem_welcome') }}</h2>
            <p class="text-center eco-text">{{ $t('message.ecosystem_description') }}</p>
          </v-card-text>
          <v-row class="white--text" :align="'start'" no-gutters>
            <v-col>
              <p class="">Admin +</p>
              <img src="https://res.cloudinary.com/freddyci/image/upload/v1676666366/logo-admin_rjh9pm.png"
                style="width:85px" alt="Logo 1">
              <p class="text-caption" style="padding:0;height:85px;">{{ $t('message.admin_d') }}
              </p>
              <v-btn style="background-color:#0D1117; color: #f5f5f5;" elevation="0" class="mb-6"
                @click="admin_dialog = true">{{ $t('message.more_info') }}</v-btn>
            </v-col>
            <v-col height="400">
              <p class="">Elevate</p>
              <img src="https://res.cloudinary.com/freddyci/image/upload/v1677893367/elevate-logo_bhxwz0.svg"
                style="width:85px" alt="Logo 2">
              <p class="text-caption" style="padding:0;height:85px;">{{ $t('message.elevate_d') }}
              </p>
              <v-btn style="background-color:#0D1117; color: #f5f5f5;" elevation="0" class="mb-6"
                @click="elevate_dialog = true">{{ $t('message.more_info') }}</v-btn>
            </v-col>
            <v-col>
              <p class="">Connect</p>
              <img :src="assets('logos/logo_connect.png')" style="width:96px;height:91px;" alt="Logo 3">
              <p class="text-caption" style="height:85px;margin-top:-5px; padding:0;">
                {{ $t('message.connect_d') }}</p>
              <v-btn style="background-color:#0D1117; color: #f5f5f5;" elevation="0" class="mb-6"
                @click="connect_dialog = true">{{ $t('message.more_info') }}</v-btn>
            </v-col>
          </v-row>
          <v-card-text class="text-center text-caption eco-text my-6">
            <div v-html="$t('message.accepting_terms', [termsUrl])"></div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <img @click="
              goToLink(
                'https://play.google.com/store/apps/details?id=com.bjjlink.android'
              )
            " style="max-width: 150px; margin-right:6px; height: auto; cursor: pointer"
              :src="assets('googleplay_badge.png')" />
            <img @click="
              goToLink('https://apps.apple.com/us/app/bjjlink/id1458988543')
            " style="max-width: 150px; height: auto; cursor: pointer" :src="assets('appstore_badge.png')" />
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div>
      <v-dialog v-model="admin_dialog" max-width="600">
        <v-card class="reg-form-bg">
          <v-card-title class="truncate eco-title">
            {{ $t('message.admin_title') }}
          </v-card-title>
          <v-card-text class="platform-content">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-expand-x-transition>
                    <img :expand="true"
                      src="https://res.cloudinary.com/freddyci/image/upload/v1678829122/register_page/admin_hero-beta_qsfkh1.png"
                      width="100%" alt="Placeholder Image 1" style="border-radius: 10px;">
                  </v-expand-x-transition>
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678829139/register_page/payments_gbjgdl.png"
                    width="100%" alt="Placeholder Image 2" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678829135/register_page/analytics_uovdat.png"
                    width="100%" alt="Placeholder Image 2" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678829223/register_page/new-lms1_nuojxl.png"
                    width="100%" alt="Placeholder Image 3" style="border-radius: 10px;">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="eco-p">{{ $t('message.admin_description') }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="0" color="secondary" @click=" admin_dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="elevate_dialog" max-width="600">
        <v-card class="reg-form-bg">
          <v-card-title class="truncate eco-title">
            {{ $t('message.elevate_title') }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678838468/register_page/elevate2_w508cd.png"
                    width="100%" alt="Placeholder Image 1" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678838467/register_page/elevate1_lqhocg.png"
                    width="100%" alt="Placeholder Image 2" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678838858/register_page/elevate3_m8xwzs.png"
                    width="100%" alt="Placeholder Image 2" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678838867/register_page/elevate4_vpmvkr.png"
                    width="100%" alt="Placeholder Image 3" style="border-radius: 10px;">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="eco-p">{{ $t('message.elevate_description') }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="0" color="secondary" @click=" elevate_dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="connect_dialog" max-width="600">
        <v-card class="reg-form-bg">
          <v-card-title class="eco-title">
            {{ $t('message.connect_title') }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" style="text-align: center;">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678839473/register_page/connect-phone_lmawox.png"
                    width="100%" alt="Placeholder Image 1" style="border-radius: 10px; width: 160px;">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="eco-p">{{ $t('message.connect_description') }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="0" color="secondary" @click="connect_dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="elevateRedirectDialog" max-width="600">
        <v-card class="reg-form-bg py-6">
          <v-card-text>
            <h2 class="text-center eco-header mb-2">{{ $t('message.ecosystem_welcome') }}</h2>
            <p class="text-center eco-text">{{ $t('message.ecosystem_register_redirect') }}</p>
          </v-card-text>
          <v-flex xs12 style="text-align: center;">
            <v-layout row wrap>
              <v-flex xs6 class="px-6">
                <p class="text-center eco-text">Elevate</p>
                <img src="https://res.cloudinary.com/freddyci/image/upload/v1677893367/elevate-logo_bhxwz0.svg"
                  style="width:85px" alt="Logo 2">
                <p class="text-center eco-text truncate" style="padding:0;">{{ $t('message.elevate_d') }}
                </p>
                <v-btn style="background-color:#303030; color: #f5f5f5;" elevation="0" class="mb-6"
                  @click="proceedToApp(true)">{{ $t('message.lets_go') }}</v-btn>
              </v-flex>
              <v-flex xs6 class="px-6">
                <p class="text-center eco-text mb-0">Connect</p>
                <img src="https://res.cloudinary.com/freddyci/image/upload/v1678141881/connect-logo-alpha_h9l88f.svg"
                  style="width:120px; margin-top:2px" alt="Logo 3">
                <p class="text-center eco-text truncate" style=" margin-top:-14px; padding:0;">
                  {{ $t('message.connect_d') }}</p>
                <v-btn style="background-color:#303030; color: #f5f5f5;" elevation="0" class="mb-6"
                  @click="proceedToApp(false)">{{ $t('message.lets_go') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import AgreementService from "@/services/AgreementService";
import AuthenticationService from "@/services/AuthenticationService";
import BeltsSelect from "@/components/inputs/BeltsSelect.vue";
import CountriesSelect from "@/components/inputs/CountriesSelect.vue";
import GenderSelect from "@/components/inputs/GenderSelect.vue";


import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      to: this.$route.query.to,
      admin_dialog: false,
      elevate_dialog: false,
      connect_dialog: false,
      elevateRedirectDialog: false,
      termsUrl: null,
      e1: 1,
      loginData: this.$route.query.u,
      ageMin: false,
      registring: false,
      academyId: this.$route.query.a,
      invitingUser: this.$route.query.i,
      academyName: null,
      fbregistring: false,
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(\s)*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(\s)*$/.test(
            v
          ) || this.$t("message.invalid_email"),
      ],
      rfName: "",
      rlName: "",
      rbelt: "",
      rgender: null,
      rnationality: "",
      remail: "",
      rfbemail: null,
      rpassword: "",
      rpassword2: "",
      error: "",
      showSendEmailDialog: false,
      fbId: null,
    };
  },
  components: {
    BeltsSelect,
    CountriesSelect,
    GenderSelect,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  created() {
    this.setLang();
    if (this.$route.query.an)
      this.academyName = atob(this.$route.query.an);
    AgreementService.latest()
      .then(data => {
        if (data) {
          this.loading = false;
          this.termsUrl = data.url;
        }
      });
  },
  methods: {
    showAdminDialog() {
      this.admin_dialog = true;
    },
    showElevateDialog() {
      this.elevate_dialog = true;
    },
    showConnectDialog() {
      this.connect_dialog = true;
    },
    goToLogin() {
      if(this.to == 'elevate')
        window.location = "/login?to=elevate";
      else
        window.location = "/login";

    },
    captureEnter(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    async register() {
      this.error = null;
      if (!this.ageMin) {
        this.error = this.$t('message.not_old_enough');
        return;
      }
      if (
        !this.remail ||
        !this.rfName ||
        !this.rlName ||
        !this.rbelt ||
        !this.rpassword ||
        this.remail == "" ||
        this.rfName == "" ||
        this.rlName == "" ||
        this.rbelt == "" ||
        this.rpassword == ""
      ) {
        this.error = this.$t("message.fill_all_params");
        return;
      }

      if (this.rpassword != this.rpassword2) {
        this.error = this.$t("message.passwords_dont_match");
        return;
      }
      this.registring = true;
      try {
        const newuser = {
          password: this.rpassword,
          email: this.remail.trim(),
          fName: this.rfName,
          lName: this.rlName,
          gender: this.rgender,
          academy: this.academyId,
          invitingUser: this.invitingUser,
          nationality: this.rnationality,
          belt: this.rbelt,
          ageMin: true,
          lang: this.browserLang,
        };
        const response = await AuthenticationService.signup(newuser);
        this.processLoggedIn(response);
      } catch (err) {
        if (err.response) this.error = err.response.data.message;
        else this.error = err.message;
        this.registring = false;
      }
    },
    activateButton() {
      if (
        !this.remail ||
        !this.rfName ||
        !this.rlName ||
        !this.rbelt ||
        !this.rpassword ||
        this.remail == "" ||
        this.rfName == "" ||
        this.rlName == "" ||
        this.rbelt == "" ||
        this.rpassword == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    proceedToApp(elevate) {
      if (elevate) {
        window.location = '/elevate'
      } else {
        window.location = "/secure/history";
      }
    },
    processLoggedIn(response) {
      if (response.data.token) {
        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.$store.dispatch("setName", response.data.user.fName);
        this.$store.dispatch(
          "setUserImage",
          response.data.user.userImageSmall
            ? response.data.user.userImageSmall
            : response.data.user.userImage
        );
        this.$store.dispatch("setLanguage", response.data.user.lang);
        this.$i18n.locale = response.data.user.lang;
        localStorage.token = response.data.token;
        if (
          response.data.hasLatestAgreement &&
          response.data.hasLatestAgreement == true
        ) {
          localStorage.hasAgreements = true;
          this.$store.dispatch("setHasAgreements", true);
        } else {
          localStorage.hasAgreements = false;
          this.$store.dispatch("setHasAgreements", false);
        }
        if (localStorage.token) {
          if (this.to == 'elevate') {
            //show dialog
            this.elevateRedirectDialog = true;
          } else {
            if (
              response.data.user.history &&
              response.data.user.history.length > 0
            )
              window.location = "/secure/home";
            else window.location = "/secure/history";
          }
        } else {
          console.log("User not logged in?");
        }
      } else {
        this.error = response.data.messageCode
          ? this.$t("message." + response.data.messageCode)
          : response.data.message;
        this.registring = false;
        this.fbregistring = false;
      }
    },
  },
  mounted() { },
  computed: {
    panelSize() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      )
        return "100%";
      else return "100%";
    },
  },
};
</script>

<style scoped>
.v-card__title{
  word-break: break-word;
}
.container-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.content-register {
  width: 600px;
  text-align: center;
}

.top-title {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.eco-header {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 1.795rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff !important;
  text-align: center;
}

.eco-title {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 1.795rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff !important;
}

.eco-text {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 0.795rem;
  font-weight: 400;
  line-height: 1.4;
  color: #f5f5f5 !important;
  text-align: center;
  padding: 0 50px;
}

.eco-p {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 0.795rem;
  font-weight: 400;
  line-height: auto;
  color: #f5f5f5 !important;
}

.registration_error {
  color: #8866ff;
}

.login-button {
  font-size: large;
  color: #8866ff;
}

.header {
  font-size: 1.495rem;
  font-weight: 500;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  color: white;
}

.newtext {
  font-size: 0.795rem;
  font-weight: 400;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  color: white;
}

.overlay-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.container-bg-tran {
  background-color: transparent !important;
}

.theme--dark .layout.wrap .container-bg {
  background: #0D1117 !important;
}

.reg-form-bg {
  background-color: #0D1117 !important;
  border-radius: 10px !important;
  padding: 10px;
  border: 1px solid #8866ff40 !important;
  box-shadow: 0 0 20px #8866ff10 !important;
  overflow: hidden;
}


.container-bg {
  background: #0D1117 !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.chev-color {
  background-color: #8866ff !important;
  color: #0D1117 !important;
}

.regbtn {
  background-color: #8866ff !important;
  color: #0D1117 !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}
.infobtn {
  background-color: #0D1117 !important;
  color: #8866ff !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}

.step-color {
  background-color: #8866ff !important;
  color: #0D1117 !important;
}
.truncate{
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* adjust as needed */
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .truncate{
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* adjust as needed */
    overflow: hidden;
}
.eco-text{
  padding: 0 20px 0 20px;
}
.container-bg {
height: auto;
padding: 20px 0px 50px 0px;
}
.v-dialog > .v-card > .v-card__text.platform-content {
    padding: 0 0px 0px;
}
}

/* iPad */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Insert styles for iPad devices here */
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  /* Insert styles for desktop devices here */
}
</style>